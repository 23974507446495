.public-pool-card {
  margin-bottom: 0 !important;
  z-index: 1;
}

.public-pool-image {
  margin: -3rem auto -0.5rem auto;
  height: 9rem;
  width: 9rem;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.public-pool-card~.share-pool-btn {
  padding-top: 2rem;
  margin-top: -1.3rem;
  margin-bottom: 2rem;
  top: 100%;
  z-index: 0;
}

.gender-container {
  position: relative;
  display: flex;
  flex-direction: row;
  color: white;
  width: 100%;
}

.gender-pill {
  padding: 1rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 500;
  transition: 1s ease-in-out;
}

.gender-boy {
  background-color: rgb(var(--gender-boy));
  background: linear-gradient(rgba(var(--gender-boy), .8) 0%, rgba(var(--gender-boy), .65) 30%, rgba(var(--gender-boy), 1) 90%);
  border-radius: 2rem 0 0 2rem;
  text-align: right;
  width: 50%;
  min-width: 4.8rem;
}

.gender-boy svg {
  float: left;
  font-size: 1.3em;
  position: absolute;

  left: 1.2rem;
  top: 1rem;
}

.gender-girl {
  background-color: rgb(var(--gender-girl));
  background: linear-gradient(rgba(var(--gender-girl), .8) 0%, rgba(var(--gender-girl), .65) 30%, rgba(var(--gender-girl), 1) 90%);
  border-radius: 0 2rem 2rem 0;
  text-align: left;
  flex-grow: 1;
  min-width: 4.8rem;
}

.gender-girl svg {
  float: right;
  font-size: 1.3em;
  position: absolute;
  right: 1.2rem;
  top: 1.1rem;
}

.card-half-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 
  p {
    color: rgba(var(--font-color), 0.75);
    font-size: 0.5em;
    letter-spacing: .5px;
    margin: -4px 0 -4px;
  }

  h6 {
    font-size: .9rem;
  }
}

.card-half {
  width: 48%;
  overflow: hidden;
  z-index: 1;

  h4 {
    font-size: 1.5rem;
    text-wrap: nowrap;
    margin-bottom: 10px;
    color: rgb(var(--primary-color));
    z-index: 3;
  }
}

.card-half-title {
  width: 51px;
  overflow-wrap: normal;
  margin-left: 0.5rem;
}

.edit-pool-icon {
  position: absolute;
  padding: 1.1rem;
  color: rgba(var(--font-color), 0.5);
}

.icon-huge, .icon-small {
  position: absolute;
  color: rgba(var(--font-color), 0.075);
  z-index: -1;
}

.icon-small {
  color: rgba(var(--font-color), 0.25);
}

.icon-huge-top-center {
  top: -7%;
  left: 50%;
  transform: translateX(-50%);
}

.icon-small-top-left {
  top: 15px;
  left: 15px;
}

.icon-small-top-right {
  font-size: 1.3rem;
  top: 15px;
  right: 15px;
}

.icon-small-top-center {
  top: -4px;
  left: 50%;
  transform: translateX(-50%);
}

.goTopHidden {
  display: none;
}

.goTop {
  display: block;
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  z-index: 1000;

  button {
    width: 37px;
    height: 37px;
    border-radius: 6px;
    outline: none;
    border: none;
  }
}