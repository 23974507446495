.logout {
    margin-top: -10px;
}

.icon-left {
    position: absolute;
    left: 1rem;
    top: .75rem;
}

.icon-inline {
    margin-right: 10px;
    margin-left: -8px;
    vertical-align: -0.2em;
}

.name {
    h5 {
        @media screen and (max-width: 40em) {
            font-size: 1.2rem;
        }
    }
    h3 {
        @media screen and (max-width: 40em) {
            font-size: 1.5rem;
        }
    }
    
}

#vip_alert {
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
    .alert {
        margin-bottom: 0;
    }
}

.my_pools-circle-lip {
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
    height: 340px;
    width: 100%;
    clip-path: ellipse(80% 55% at 50% 43%);
    // margin-top: -20px;
    border-bottom-left-radius: 100% 20%;
    border-bottom-right-radius: 100% 20%;
    box-shadow: rgba(0, 128, 128, .75) 0px -40px 40px -40px inset;

    &-short {
        margin-top: -40px;
    }
    &-shorter {
        margin-top: -80px;
    }
    &-shortest {
        margin-bottom: -320px;
    }
    &-long {
        height: 540px;
        padding-bottom: 190px;
        margin-bottom: -150px;
    }
}

.my_pools-profile {
    width: 100%;
    height: 100%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.my_pools-avatar {
    width: 9rem;
    height: 9rem;
    margin-top: 1rem;
    border: solid 3px rgb(var(--bg-color));
    background-color: rgb(var(--bg-color));
    border-radius: 50%;
    position: relative;

    a img {
        border-radius: 50%;
    }
}

.my_pools-avatar-edit-btn {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: rgb(var(--font-color));
    position: absolute;
    bottom: 4px;
    right: -10px;
    color: rgb(var(--bg-color));
    display: flex;
    justify-content: center;
    align-items: center;
}

.admin-dashboard-btn {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    background-color: rgb(var(--bg-color));
    color: rgb(var(--primary-color));
    top: 5rem;
    right: 1.5rem;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}

.my_pools-vip-badge {
    position: absolute;
    left: -4px;
    bottom: -14px;
    width: 50px;
}

.my_pools-name h3 {
    color: rgb(var(--bg-color));
    font-size: 1.5rem;
}

.my_pools-add-pool {
    margin-top: 1rem;
    margin-bottom: 2rem;

    a {
        cursor: pointer;
        color: rgb(var(--bg-color));
        border-radius: 2rem;
        padding: 0.7rem 1.75rem;
        position: relative;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        background-color: rgba(var(--bg-color), 0.2);
        font-size: .9rem;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    :hover {
        background-color: rgba(var(--bg-color), 1);
    }
    a:hover {
        color: rgb(var(--primary-color));
        text-decoration: none;
    }
}

.my_pools-profile-info {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}