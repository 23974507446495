:root {
  --primary-color: 0, 199, 192;
  --primary-gradient: 0, 214, 193;
  --secondary-color: 0, 185, 191;
  --secondary-gradient: 0, 185, 191;
  /* --secondary-color: 44, 171, 178; */
  --font-color: 116, 116, 116;
  --bg-color: 255, 255, 255;
  --bg2-color: 233, 238, 244;
  --contrast-color: 70, 70, 70;
  --gender-boy: 0, 143, 251;
  --gender-girl: 255, 105, 179;
  --border-radius: 1rem;
  --version: "1.4.1";
}

[data-theme="dark"] {
  --primary-color: 0, 199, 192;
  --primary-gradient: 1, 255, 205;
  --secondary-color: 9, 237, 218;
  --secondary-gradient: 9, 237, 218;
  --font-color: 204, 204, 204;
  --bg-color: 243, 243, 243;
  --bg2-color: #d1d1d1;
  --contrast-color: 10, 10, 10;
  --gender-boy: 0, 187, 247;
  --gender-girl: 255, 105, 179;
}

body {
  /* background-color: rgb(var(--primary-color));
  background-image: linear-gradient(to right,
      rgb(var(--primary-color)),
      rgb(var(--secondary-color))); */
  background-color: rgb(var(--bg2-color)) !important;
  color: rgb(var(--font-color));
  font-family: "Poppins", sans-serif;
  height: 100%;
  min-height: 100vh;
}

#Body {
  /* min-height: 100vh; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.light-theme {
  background-color: rgb(var(--bg-color));
}

.medium-theme {
  background-color: rgba(var(--bg2-color), 0.75);
}

.primary-color {
  color: rgb(var(--primary-color)) !important;
}

.boy-color {
  color: rgb(var(--gender-boy))
}
.girl-color {
  color: rgb(var(--gender-girl))
}

.light-color {
  color: rgba(var(--font-color), 0.5);
}

.backdrop {
  background-color: rgb(var(--bg-color));
  /* height: 100%; */
  padding-top: 2rem;
  padding-bottom: 1.15rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  border-radius: 1.5rem;
  /* display: block; */
  position: relative;
  overflow: auto;
  /* box-shadow: 0 0 28px rgba(0, 0, 0, 0.02), 0 -10px 10px rgba(0, 0, 0, 0.05) */
}

.box-shadow {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01), 0 2px 2px rgba(0, 0, 0, 0.01),
    0 4px 4px rgba(0, 0, 0, 0.01), 0 8px 8px rgba(0, 0, 0, 0.01),
    0 16px 16px rgba(0, 0, 0, 0.01);
}

.medium-12,
.medium-6 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.round-bottom {
  border-radius: 0 0 1.5rem 1.5rem;
  box-shadow: 0 14px 28px rgba(200, 200, 200, 0.25),
    0 10px 10px rgba(200, 200, 200, 0.22);
}

a,
a:focus,
a:hover {
  color: rgb(var(--primary-color));
}

p {
  font-size: 80%;
}

p strong {
  font-weight: 500;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}

h2 {
  font-weight: 500;
  margin: 0;
}

h4 {
  margin-bottom: .175rem;
}

h5 {
  font-weight: 500;
}

h6 {
  font-weight: 400;
}

.pacifico-regular {
  font-family: "Pacifico", cursive;
  font-weight: 400;
  font-style: normal;
}

.primary {
  background: #f2b632 !important;
}

.secondery {
  background: #2a2c43 !important;
  color: #ffffff !important;
}

.left {
  float: left !important;
}

#hidden-data {
  display: none;
}

.profile {
  width: 100%;
  padding-left: 16px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
}

.hello {
  margin-bottom: 0;
  font-weight: 600;
  color: rgba(var(--font-color), 0.7);
}

.avatar,
.avatar-edit {
  width: 7rem;
  height: 7rem;
  overflow: hidden;
  border-radius: 50%;
}

.avatar-edit {
  width: 9rem;
  height: 9rem;
  margin-top: 3rem;
}

input {
  color: #333333;
  border-radius: 0.7rem;
}

textarea {
  min-height: 7em;
  padding: 0.75rem;
}

.small-link {
  font-size: 0.7rem;
}

.btn {
  border-radius: 0.6rem;
}

.btn-sm {
  padding: 0.5rem 0.8rem 0.45rem 0.8rem;
  border-radius: 0.6rem;
}

.btn-lg,
.btn-block {
  position: relative;
  padding: 0.7rem 1rem;
  /* font-weight: 500; */
  font-size: 0.9em;
  border-radius: 50px;
}

.btn-fb {
  position: relative;
}

.btn-fb>svg {
  position: absolute;
  left: 11px;
  top: 13px;
}

.btn-group {
  width: 100%;
}

.btn-group-toggle>.btn,
.btn-group-toggle>.btn-group>.btn {
  font-size: 0.7em;
  padding-left: 0.7em;
  padding-right: 0.7em;
}

.cancel-submit {
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
}

.btn-link-white {
  color: rgb(var(--font-color)) !important;
}

button>span {
  margin-top: 4px;
}

button:focus {
  box-shadow: none !important;
}

.btn-primary,
.btn-outline-primary:hover {
  background-color: rgb(var(--primary-color));
  background-image: linear-gradient(to bottom right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
  border-color: rgb(var(--primary-color));
  box-shadow: 0 1em 1.2em -1em rgba(var(--primary-color), 0.75);
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:active,
.btn-outline-primary:focus,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active {
  background-color: rgb(var(--primary-color));
  background-image: linear-gradient(to bottom right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
  border-color: rgb(var(--primary-color));
  color: rgb(var(--bg-color));
}

.btn-outline-primary {
  border-color: rgb(var(--primary-color));
  color: rgb(var(--primary-color));
}

.btn-outline-primary.btn-fb {
  background-color: rgb(var(--bg-color));
  border-color: #4267b2;
  color: #4267b2;
}

.btn-outline-primary.btn-fb:hover {
  background-color: rgb(var(--bg-color));
  border-color: #4267b2;
  color: #4267b2;
}

.btn-link,
.btn-link:hover {
  color: rgb(var(--primary-color));
}

.btn-light,
.btn-light:hover,
.btn-light:focus,
.btn-light:active,
.btn-light:not(:disabled):not(.disabled):active,
.btn-outline-light:active,
.btn-outline-light:focus,
.btn-outline-light:not(:disabled):not(.disabled):active,
.btn-outline-light:not(:disabled):not(.disabled).active {
  background-color: rgb(var(--bg-color));
  border-color: rgb(var(--bg-color));
  color: rgb(var(--primary-color));
}

.btn-outline-light {
  border-color: rgb(var(--bg-color));
  color: rgb(var(--bg-color));
}

.boxed {
  width: 100%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}

.custom-file {
  margin-bottom: 1rem;
  height: auto;
}

.custom-file-label {
  text-align: left;
  line-height: 1.8;
  height: calc(2.2em + 0.75rem + 2px);
  border-radius: 50px;
  padding: 0.6rem 1rem;
}

#fileInputLabel.image-selected {
  background: url(https://cf.ltkcdn.net/pregnancy/images/orig/258001-1600x1067-expecting-parents.jpg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border: none;
}

.image-selected {
  border: none !important;
}

.custom-file-label::after {
  height: calc(2.2em + 0.75rem);
  border-radius: 0 50px 50px 0;
  padding: 0.7rem 1.5rem;
}

/* .custom-pool-file-label {
  position: relative;
  height: auto;
  border: 2px dashed rgb(var(--primary-color));
  padding: 1.5rem;
}

.custom-pool-file-label::after {
  display: none;
} */

.form-control-range {
  -webkit-appearance: none;
  width: 100%;
  height: 1rem;
  border: 1px solid #ced4da;
  background: #e9ecef;
  outline: none;
  padding: 0;
  margin: 0;
}

.form-control-range::-webkit-slider-thumb {
  appearance: none;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background: rgb(var(--primary-color));
  cursor: pointer;
}

.form-control-range::-webkit-slider-thumb:focus {
  appearance: none;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  background: rgb(var(--primary-color));
  cursor: pointer;
}

.form-control-range:hover {
  background: #fff;
}

.center {
  text-align: center;
}

.form-label-group input,
.form-label-group textarea,
#dueDatePicker, #limitDatePicker {
  margin: 0 !important;
}

.invalid-feedback {
  margin-top: 0.2rem;
  margin-bottom: -0.4rem;
}

.off-canvas {
  z-index: 1 !important;
}

.spinner-border-sm {
  border-width: 0.15em;
  position: absolute;
  top: 0.7em;
  left: 0.9em;
}

.pool-card {
  width: auto !important;
  margin: 0 1rem;
  border-width: 2px 0 0 0;
  border-color: rgb(var(--primary-color));
  border-radius: 0.7rem;
}

.people-you-might-know {
  background-color: #fefefe;
  padding: 1rem 0 0;
  border: 1px solid #cacaca;
  box-shadow: 0 0 3.125rem rgba(0, 0, 0, 0.18);
}

.people-you-might-know .add-people-header {
  padding: 0 0.9375rem;
  border-bottom: 0.0625rem solid #cacaca;
}

.people-you-might-know .add-people-header .header-title {
  font-weight: bold;
}

.people-you-might-know .add-people-section {
  margin: 1rem 0 0;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #cacaca;
}

.people-you-might-know .add-people-section .about-people {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.people-you-might-know .add-people-section .about-people .about-people-avatar {
  padding-right: 0.5rem;
  padding-left: 0;
}

.people-you-might-know .add-people-section .about-people .about-people-avatar .avatar-image {
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: 0.0625rem solid #cacaca;
}

.people-you-might-know .add-people-section .about-people .about-people-author {
  -webkit-flex: 1 0 0;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
}

.people-you-might-know .add-people-section .about-people .about-people-author .author-name {
  color: #0a0a0a;
  margin: 0.375rem 0 0;
}

.people-you-might-know .add-people-section .about-people .about-people-author .author-location,
.people-you-might-know .add-people-section .about-people .about-people-author .author-mutual {
  color: #8a8a8a;
  margin-bottom: 0;
  font-size: 0.85em;
}

.people-you-might-know .add-people-section .add-friend {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

@media screen and (max-width: 39.9375em) {
  .people-you-might-know .add-people-section .add-friend {
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
}

.people-you-might-know .add-people-section .add-friend .add-friend-action {
  margin-top: 0.7rem;
}

.people-you-might-know .view-more-people {
  margin: 0.7rem 0;
}

.people-you-might-know .view-more-people .view-more-text {
  margin-bottom: 0;
  text-align: center;
}

.people-you-might-know .view-more-people .view-more-text .view-more-link {
  color: #1779ba;
}

.people-you-might-know .view-more-people .view-more-text .view-more-link:hover,
.people-you-might-know .view-more-people .view-more-text .view-more-link:focus {
  text-decoration: underline;
}

.img-holder {
  position: relative;
  width: 100%;
  height: 10rem;
  border: 2px dashed rgb(var(--primary-color));
  border-radius: 0.7rem;
  margin-bottom: 1rem;
  box-sizing: border-box;
}

.img {
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
  object-fit: cover;
}

#input[type="file"] {
  display: none;
}

.pool-image-label {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  border-radius: 0.7rem;
  display: flex;
  justify-content: center;
}

.image-upload {
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
  cursor: pointer;
}

.breadcrumbs {
  padding-left: 2rem;
}
.breadcrumbs a {
  color: rgb(var(--primary-color));
}
.breadcrumbs a:hover {
  text-decoration: none;
  color: rgb(var(--primary-color));
}
.breadcrumbs a::after {
  border-bottom: 2px solid rgb(var(--primary-color));
}
.breadcrumbs li:last-child > button {
  color: rgb(var(--font-color)) !important;
  font-weight: 600 !important;
}

.rainbow-table_header-content {
  font-weight: 600;
}

/* Date Picker */

select::-ms-expand {
  display: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
}

#dueDatePicker,
#limitDatePicker {
  margin-bottom: 1rem;
}

#dueDatePicker input,
#limitDatePicker input {
  border-color: transparent !important;
  background-color: rgba(var(--primary-color), 0.08) !important;
  border-radius: 50px !important;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1) !important;
  padding: 22px 1rem;
}

#dueDatePicker_modal h1,
#limitDatePicker_modal h1 {
  color: rgb(var(--primary-color));
}

.bXhqXG:focus, .bXhqXG:active {
  background-color: rgb(var(--primary-color)) !important;
  box-shadow: 0 0 2px rgb(var(--secondary-color)) !important;
}

.fVhmoN:focus, .fVhmoN:active {
  border: 2px solid rgb(var(--primary-color)) !important;
  box-shadow: 0 0 2px rgb(var(--secondary-color)) !important;
}

#dueDatePicker.is-invalid input,
#limitDatePicker.is-invalid input,
#datePicker.is-invalid input,
#timePicker.is-invalid input {
  border-color: #dc3545 !important;
}

#time-picker_modal fieldset {
  background-color: rgb(var(--primary-color)) !important;
  box-shadow: 0 0 2px rgb(var(--secondary-color)) !important;
}
#time-picker_modal input:checked + label {
  background-color: rgb(var(--secondary-color)) !important;
  box-shadow: 0 0 2px rgb(var(--secondary-color)) !important;
}
#dueDatePicker_modal header h1,
#datePicker_modal header h1,
#limitDatePicker_modal header h1 {
  color: rgb(var(--primary-color)) !important;
}
#dueDatePicker_modal section button::after,
#datePicker_modal section button::after,
#limitDatePicker_modal section button::after {
  background-color: rgb(var(--primary-color)) !important;
}
#dueDatePicker_modal section button[data-selected="true"],
#datePicker_modal section button[data-selected="true"],
#limitDatePicker_modal section button[data-selected="true"] {
  background-color: rgb(var(--primary-color)) !important;
}
#dueDatePicker_modal section div:has(select)::after,
#datePicker_modal section div:has(select)::after,
#limitDatePicker_modal section div:has(select)::after {
  color: rgb(var(--primary-color)) !important;
}

.gWamrW, .TuMQS,
.VVlBC, .jToHRQ,
#dueDatePicker_modal_calendar button:not(:disabled) svg,
#datePicker_modal_calendar button:not(:disabled) svg,
#limitDatePicker_modal_calendar button:not(:disabled) svg,
.sBIMs::after,
.knqtDi::after {
  color: rgb(var(--primary-color)) !important;
}

.digNTv, .gRgVBg, .gRgVBg:hover {
  background-color: rgb(var(--primary-color)) !important;
}

.jdLcdA:hover, .jdLcdA:focus, .jdLcdA:active {
  color: rgb(var(--secondary-color)) !important;
}

.gRgVBg:hover, .ijIKAc:hover {
  background-color: rgb(var(--secondary-color)) !important;
}

.jNqbkq:hover, .eSyZqa:hover {
  background-color: transparent;
  border: 1px solid rgb(var(--primary-color)) !important;
}

.iVIvma:focus,
.iVIvma:active,
.iVIvma:visited {
  border: 0.125rem rgb(var(--primary-color)) solid !important;
  box-shadow: 0 0 2px rgb(var(--primary-color)) !important;
}

.figxQJ:focus,
.figxQJ:active {
  border: 2px solid rgb(var(--primary-color)) !important;
  box-shadow: 0 0 2px rgb(var(--primary-color)) !important;
}

.react-parallax-bgimage {
  object-fit: cover;
}

.react-confirm-alert {
  border-radius: 2rem !important;
}

.editor-canvas {
  max-width: 250px;
  height: auto !important;
}

.btn-addition {
  background-color: rgb(var(--bg-color));
  padding: .5rem 2rem;
  border-radius: 0 0 1.5rem 1.5rem;
  margin-top: -1rem;
}

.form-password-show-hide {
  position: absolute;
  top: 0.85rem;
  right: 0.8rem;
  cursor: pointer;
}
/* .form-control.is-invalid ~ .form-password-show-hide, .was-validated .form-control:invalid ~ .form-password-show-hide {
  right: 2.2rem;
} */

#inputPassword.form-control.is-invalid,
#inputPasswordConfirm.form-control.is-invalid {
  background-position: right calc(0.375em + 2rem) center;
}

.form-check {
  padding-left: 0;
}
.form-check input {
  margin-top: 6px;
}
.form-check label {
  margin-left: 1rem;
}

label > .rainbow-input_faux {
  width: 25px !important;
  height: 25px !important;
  border-color: rgba(var(--primary-color), 1) !important;
}
label > .rainbow-input_faux::after {
  border-color: rgba(var(--primary-color), 1) !important;
}
input:not([disabled]) + label span {
  color: rgb(var(--font-color));
}
label > .rainbow-input_faux + span {
  font-size: .85rem !important;
}
legend {
  margin-left: .5rem !important;
}
.rainbow-p-vertical_large legend {
  color: rgb(var(--font-color));
}

.xl-input span {
  width: 1.75rem;
  right: 1.7rem;
}

.xl-input span svg {
  width: 2.5rem !important;
  height: 2.5rem !important;
  font-size: 2.5rem !important;
}

@media only screen and (max-width: 600px) {
  .kdmJSn {
    align-self: center;
    width: 90% !important;
    border-radius: 0.7em !important;
  }
}

@media only screen and (min-width: 992px) {
  .mobile-header {
      display: none;
  }
  .margin-on-large {
      margin-top: 3rem;
  }
  .padding-on-large {
      padding-top: 3rem;
  }
  .btn-wide {
      width: 600px !important;
  }
  .editor-canvas {
    max-width: 100%;
    height: auto !important;
  }
}

@media only screen and (min-width: 900px) {
}

@media only screen and (max-width: 400px) {
  .form-label-group>label {
    height: 3rem !important;
    font-size: .75rem;
  }
  #datePicker input {
      font-size: 1.7rem;
  }
  #timePicker input {
      font-size: 2rem;
  }
  .xl-input span {
      width: 1.5rem;
      right: 1.5rem;
  }
  .xl-input span svg {
    width: 2rem !important;
    height: 2rem !important;
    font-size: 2rem !important;
  }
}