#betzCards {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
}

.betz-card {
  background-color: rgb(var(--bg-color));
  width: 100%;
  height: auto;
  max-height: 240px;
  padding: 1rem 1.15rem;
  overflow: hidden;
  margin-bottom: 0.2rem;
  transition: max-height .5s ease;
}

.betz-card-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.betz-card-header span {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.betz-card-content {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.betz-card-content span {
  display: block;
  font-size: 0.6em;
}

.metric-optional {
  font-size: 0.75em;
  line-height: 1em;
}

.betz-info {
  display: block;
  max-width: 47%;
  max-height: 73px;
  transition: max-height 0.5s ease;
  color: rgb(var(--primary-color));
}

.betz-info h6, .total-points h6 {
  margin-bottom: 0.1em;
  line-height: 1em;
}

.betz-info p, .total-points p {
  font-size: 0.7em;
  line-height: 1.2em;
  margin-bottom: 0;
}

.mini .betz-info {
  max-height: 31px;
  overflow: hidden;
}

.total-points {
  display: block;
  text-align: right;
  max-height: 73px;
  min-width: 43px;
  transition: max-height 0.5s ease;
  color: rgb(var(--primary-color));
}

.betz-gender-boy {
  color: rgb(var(--gender-boy));
}

.betz-gender-girl {
  color: rgb(var(--gender-girl));
}

.betz-comment,
.betz-comment p {
  width: 100%;
  text-align: center;
  margin: 0;
  color: rgba(var(--font-color), 0.75);
}

.comment-separator {
  width: 100%;
  margin: 1.5rem auto 1rem auto;
  border-top: 1px solid rgba(var(--font-color), 0.1);
}

.betz-edit-collapse {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.betz-edit-collapse a,
.betz-edit-collapse .btn-sm {
  font-size: 1rem;
  padding: 0.2rem 0.6rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  border-radius: 5px;
  color: rgba(var(--font-color), 0.75);
  border: 1px solid rgba(var(--font-color), 0.5);
}

.betz-edit-collapse a:focus,
.betz-edit-collapse .btn-sm:focus {
  color: rgb(var(--bg-color));
}

.betz-edit-collapse button svg {
  pointer-events: none;
}

.betz-card.mini {
  max-height: 60px;
  transition: max-height .5s ease;
}

/* .mini .betz-card-content,
.mini .betz-comment {
  max-height: 0;
} */

.betz-feed-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.betz-feed-top h4 {
  margin: 0;
}

.calendar-view {
  width: 3.5rem;
  height: 2rem;
  padding: .25rem;
  border-radius: 5px;
  border: 1px solid rgba(var(--font-color), 0.75);
}

.expand-collapse-all {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 2rem;
  padding: .25rem;
  border-radius: 5px;
  border: 1px solid rgba(var(--font-color), 0.75);
}

#arrow {
  position: absolute;
  top: -1px;
  left: 0;
  margin: 0;
  width: 3.5rem;
  height: 1.9rem;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

label[for="arrow"] {
  position: relative;
  width: 0;
  height: 0;
  margin-left: 5px;
  margin-bottom: 1px;
  background: rgb(var(--font-color));
  transition: transform 0.3s;
  -webkit-transition: transform 0.3s;
  cursor: pointer;
}

.expand-collapse-all:hover label[for="arrow"] {
  background: rgb(var(--bg-color));
}

label[for="arrow"]:before {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  background: inherit;
  transition: inherit;
  -webkit-transition: inherit;
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

label[for="arrow"]:after {
  content: "";
  position: absolute;
  width: 8px;
  height: 2px;
  left: 5px;
  background: inherit;
  transition: inherit;
  -webkit-transition: inherit;
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

#arrow:checked+label[for="arrow"]:before {
  transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

#arrow:checked+label[for="arrow"]:after {
  transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.betz_feed-share-btn, .betz_feed-place-betz-btn {
  width: 100%;
  display: inline-block;
  padding: .75rem;
  border: 1px solid rgb(var(--primary-color));
  border-radius: 1.5rem;
  color: rgb(var(--primary-color));
  margin-bottom: 1rem;

  &:hover {
    background-color: rgb(var(--primary-color));
    color: rgb(var(--bg-color));
    text-decoration: none;
  }

  &:focus {
    outline: none;
  }
}

.bet-owner {
  border-left: 6px solid rgb(var(--primary-color));
  padding-left: 12px;
}