.app__footer {
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 40px;
    box-shadow: rgba(0, 128, 128, 0.75) 0px 40px 40px -40px inset;

    img {
        max-height: 56px;
    }
}

.app__footer-copyright {
    color: rgb(var(--bg-color));
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
    margin: 0;
    padding-bottom: 8px;
    
    a, a:hover {
        color: rgb(var(--bg-color));
    }
}