.rainbow-p-half {
    width: 55%;
    display: inline-block;
}

.forgot-pass {
    width: 45%;
    display: inline-block;

    a {
        font-size: 0.8rem;
    }
}