.mobile-header {
    position: relative;
    z-index: 1;
}

.navbar {
    padding: .5rem 0;
    /* border-radius: var(--border-radius) var(--border-radius) 0 0; */
}

.navbar h3 {
    margin-bottom: 0;
}

@media screen and (max-width: 40em) {
    .navbar h3 {
        font-size: 1.5rem;
    }
}

.navbar-white {
    background-color: rgb(var(--bg2-color));
}

.navbar-white a {
    color: rgb(var(--font-color));
}

.navbar-color {
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
}

.navbar-color,
.navbar-photo,
.navbar-color a,
.navbar-photo a {
    color: white;
}

.navbar-color .line-menu,
.navbar-photo .line-menu {
    background-color: white;
}

.navbar-color .arrow-back::before,
.navbar-color .arrow-back::after {
    border-color: white;
}

.arrow-back {
    position: relative;
    display: inline-block;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    transform: rotate(-135deg);
}

.arrow-back::before {
    content: '';
    width: 100%;
    height: 100%;
    border-width: 2px 2px 0 0;
    border-style: solid;
    border-color: rgb(var(--font-color));
    transition: .2s ease;
    display: block;
    transform-origin: 100% 0;
}

.arrow-back::after {
    content: '';
    float: left;
    position: relative;
    top: -100%;
    width: 100%;
    height: 100%;
    border-width: 0 2px 0 0;
    border-style: solid;
    border-color: rgb(var(--font-color));
    transform-origin: 100% 0;
    transition: .2s ease;
}

.navbar-button {
    width: 4rem;
    height: 3rem;
    border: none;
}

button.navbar-button:focus {
    outline: none;
}

.wrapper-menu {
    width: 21px;
    height: 18px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;
    transition: transform 330ms ease-out;
}

.wrapper-menu.open {
    transform: rotate(-45deg);
}

.line-menu {
    background-color: rgb(var(--font-color));
    width: 100%;
    height: 2px;
}

.line-menu.half {
    width: 50%;
}

.line-menu.start {
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: right;
}

.open .line-menu.start {
    transform: rotate(-90deg) translateX(0.15rem);
}

.line-menu.end {
    align-self: flex-end;
    transition: transform 330ms cubic-bezier(0.54, -0.81, 0.57, 0.57);
    transform-origin: left;
}

.open .line-menu.end {
    transform: rotate(-90deg) translateX(-0.15rem);
}

.navbar-collapse {
    padding-left: 1.5em;
    padding-right: 1.5em;
    margin-top: 0;
    opacity: 1;
    pointer-events: all;
    transition: 1s ease;
}

.navbar-collapse:not(.show) {
    display: block;
    margin-top: -14.5rem;
    opacity: 0;
    transition: 1s;
    pointer-events: none;
    z-index: -1;
}