.card {
    border: none;
    border-radius: 2rem;
    margin-bottom: 1rem;
}

.pool_feed {

    a, a:hover, &:hover {
        text-decoration: none;
    }
}

.pool_feed-card {
    position: relative;
    background-color: rgb(var(--bg-color));
    height: 8rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
    border: none;
    border-radius: 2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    a, a:hover {
        text-decoration: none;
    }

    @media only screen and (max-width: 600px) {
        margin-left: .75rem;
        margin-right: .75rem;
    }
}

.pool_feed-card-body {
    padding: 0;
    padding-left: 25px;
    display: flex;
    flex: 1;
    height: 100%;
    color: rgb(var(--font-color));

    h5, p {
        padding: 0;
        margin: 0;
    }
}

.pool_feed-card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    flex-grow: 1;
    height: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
}

.pool_feed-card-title, .pool_feed-card-desc {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
            line-clamp: 2; 
    -webkit-box-orient: vertical;
}

.pool_feed-card-title {
    color: rgb(var(--contrast-color));
    line-height: 1.1rem;

    @media only screen and (max-width:400px) {
        font-size: .9rem;
    }
}

.pool_feed-card-desc {
    line-height: 1rem;

    @media only screen and (max-width:400px) {
        font-size: 75%;
    }
}

.pool_feed-card-buttons {
    width: 100%;
    min-height: 9rem;
    display: flex;
    /* flex-direction: column; */
    justify-content: space-between;
    align-content: space-between;
}

.pool_feed-card-image-votes {
    position: absolute;
    width: 3.5rem;
    height: 3.5rem;
    bottom: 0px;
    left: 0px;
    transform: translate(50%, -50%);
}

.pool_feed-card-image {
    width: 8rem;
    height: 8rem;
    flex-grow: 0;
    flex-shrink: 0;
    border-radius: 2rem;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.pool_feed-card-votes {
    position: absolute;
    display: block;
    width: 3rem;
    height: 3rem;
    bottom: 7px;
    left: 7px;
    border-radius: 50%;
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
    color: #fff;
    text-align: center;
    
    h2 {
        font-size: 1.3rem;
        font-weight: 400 !important;
        margin-top: 0.15rem;
    }
    
    p {
        font-size: 0.6rem;
        margin-top: -.35rem;
        letter-spacing: 0.05rem;
    }
}

.pool_feed-card-arrow {
    width: 50px;
    height: 100%;
    display: block;
}

.chevron-right {
    position: relative;
    top: 50%;
    text-align: center;
    // height: 10px;
    width: 40px;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg);

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 2px;
        width: 20px;
        background: rgba(var(--font-color), .5);
        -webkit-transform: skew(0deg, -30deg);
        -moz-transform: skew(0deg, -30deg);
        -ms-transform: skew(0deg, -30deg);
        -o-transform: skew(0deg, -30deg);
        transform: skew(0deg, -30deg);
    }
        
    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: 2px;
        width: 20px;
        background: rgba(var(--font-color), .5);
        -webkit-transform: skew(0deg, 30deg);
        -moz-transform: skew(0deg, 30deg);
        -ms-transform: skew(0deg, 30deg);
        -o-transform: skew(0deg, 30deg);
        transform: skew(0deg, 30deg);
    }
}

.pool_feed-add-first-pool {
    border: 2px dashed rgb(var(--primary-color));
    border-radius: 1.2rem;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
        font-size: 2.5rem;
        color: rgb(var(--primary-color));
    }
}

#view-pool-btn {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
}

time {
    color: #666666 !important;
    font-size: 13px;
    font-family: 'Georgia', 'Times New Roman', Times, serif
}

b {
    display: block
}

.people-you-might-know {
    padding: 0 !important;
    margin-bottom: 10px;
    border-radius: 5px;
}

.btn-color {
    background-color: red;
}

.edit-btn-color {
    background-color: green;
}

@media only screen and (max-width: 359px) {
    .pool-card {
        margin: 0 0 1rem 0;
        border-radius: 0;
    }
    .pool_feed-card {
        height: auto;
        flex-direction: column;
        padding-top: 1rem;
    }
    .pool_feed-card-body {
        padding-right: 25px;
        text-align: center;
    }
    .pool_feed-card-content {
        align-items: center;
    }
    .pool_feed-card-arrow {
        display: none;
    }
}