.faq-top {
    color: rgb(var(--bg-color));
    padding-top: 110px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -140px;

    p {
        font-size: 100%;
    }

    @media only screen and (min-width: 992px) {
        padding-top: 30px;
        padding-bottom: 30px;
        justify-content: space-evenly;
        margin-top: 0;
    }
}

.faq-contact-btn {
    margin-top: 1rem;
    margin-bottom: 1rem;

    a {
        cursor: pointer;
        color: rgb(var(--bg-color));
        border-radius: 2rem;
        padding: 0.7rem 1.75rem;
        position: relative;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        user-select: none;
        background-color: rgba(var(--bg-color), 0.2);
        font-size: 0.9rem;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
}

.faq-bottom {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
}

.faq-donut-chart {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
}

.faq-badge {
    width: 50px;
    position: absolute;
    top: 7px;
    right: 12px;
}