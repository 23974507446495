.app__header {
    width: 100%;
    // background-color: rgb(var(--primary-color));
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 100;

    a, a:hover {
        color: white;
    }
}

.app__header-item {
    width: 74px;
    margin: 1rem 1.5rem;
    text-align: center;
    text-decoration: none;
    text-transform: capitalize;
    text-wrap: nowrap;
}

.app__header-logo {
    width: 200px;
    margin: 0.5rem 1.5rem;
}

@media screen and (max-width:991px) {
    .app__header {
        display: none;
        justify-content: space-between;
    }

    .app__header-item {
        display: none;
    }

    .app__header-logo {
        min-width: 160px;
    }
}