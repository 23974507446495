.app__welcome {
    font-family: "Poppins", sans-serif;
    // background-color: rgb(var(--primary-color));
    // background-image: linear-gradient(to right,
    //   rgb(var(--primary-color)),
    //   rgb(var(--secondary-color)));
    color: #fff;
    position: relative;
    z-index: 0;
    padding-bottom: 50px;
    margin-bottom: -50px;
    overflow: hidden;

    @media screen and (min-width:992px) {
        overflow: visible;
    }
}

.app__welcome-logo {
    width: 200px;
    margin: 0.5rem 1.5rem;
    position: absolute;
    z-index: 2;

    img {
        max-width: 200px;
    }

    @media screen and (min-width:992px) {
        display: none;
    }
}

.app__welcome-mobile-header {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;

    #navbarToggler {
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
        background: hsla(0, 0%, 100%, .25);
    }
    #navbarToggler.show {
        padding-top: 3.5rem;
        padding-bottom: 0.75rem;
        margin-top: -3.5rem;
    }
    .navbar-button {z-index: 1;}
}

.app__welcome-image {
    min-height: 560px;
    z-index: -1;

    @media screen and (min-width:992px) {
        margin-top: -75px;
    }

    // img {
    //     height: 100% !important;
    // }
}

.app__welcome-image-bottom {
    position: absolute;
    right: 0;
    left: 0;
    bottom: 0;
    pointer-events: none;
    z-index: 2;

    img {
        width: 100%;
        height: auto;
        display: block;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        float: left;
    }
}

#welcome {
    min-height: 560px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.app__welcome-button {
    font-size: 1.25rem;
    font-weight: 500;
}

.app__welcome-steps {
    width: 100%;
    background-color: #fff;
    display: flex;
    flex-direction: column;

    & :nth-child(even) {
        flex-direction: row-reverse;
    }

    @media screen and (max-width:1200px) {
        padding-left: 2rem;
        padding-right: 2rem;
    }

    @media screen and (max-width:900px) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.app__welcome-step {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 0;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;

    @media only screen and (max-width:900px) {
        flex-direction: column !important;
    }
}

.app_welcome-steps-text {
    margin: 0 1rem;
    flex: 1;

    h2 {
        color: rgb(var(--primary-color));
        margin-bottom: 1rem;

        @media only screen and (max-width:900px) {
            font-size: 1.8rem;
        }
    }
    
    p {
        color: rgb(var(--font-color));
        font-size: 1.1rem;

        @media only screen and (max-width:900px) {
            font-size: 1rem;
        }
    }
}

.app_welcome-steps-image {
    max-width: 400px;
    margin: 0 1rem;
}

.app__welcome-wave-footer {
    position: absolute;
    min-width: 480px;
    left: 50%;
    transform: translate(-50%, 0);

    // @media screen and (max-width:900px) {
    //     margin-top: -3px;
    // }
}