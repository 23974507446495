.dashboard-top, .dashboard-lists {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 0 1rem;
    
    @media only screen and (max-width:1100px) {
        flex-direction: column;
    }
}

.dashboard-top-card, .dashboard-list {
    margin: .75rem;
    padding: 1.5rem;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    
    @media only screen and (max-width:1100px) {
        width: 100%;
        margin: 0;
        margin-bottom: .75rem;
    }
}

.dashboard-top-card {
    flex-direction: row;
}

.dashboard-icon {
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to bottom right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
    color: rgb(var(--bg-color));
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboard-list-icon {
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to bottom right,
      rgb(var(--primary-gradient)),
      rgb(var(--secondary-gradient)));
    color: rgb(var(--bg-color));
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    
    @media only screen and (max-width:480px) {
        width: 38px;
        height: 38px;
    }
}

.dashboard-info {
    text-align: right;

    h5 {
        font-weight: 300;
    }
    h3 {
        margin: 0;
        color: rgb(var(--contrast-color));
    }
}

.dashboard-list {
    flex-direction: column;
    align-items: flex-start;
}

.dashboard-list-title {
    font-weight: 300;
    margin-bottom: .75rem;
    color: rgb(var(--contrast-color));
}

.dashboard-list-item {
    width: 100%;
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: row;

    img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
    
        @media only screen and (max-width:480px) {
            width: 38px;
            height: 38px;
        }
    }

    .vip-user-badge-sm {
        position: absolute;
        bottom: -14px;
        left: -7px;
        width: 28px;
        height: auto;
    
        @media only screen and (max-width:480px) {
            bottom: -9px;
            left: -2px;
            width: 20px;
        }
    }

    .dashboard-list-item-content {
        display: flex;
        flex: 1;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-left: .75rem;

        .dashboard-list-item-info {
            h4 {
                color: rgb(var(--primary-color));
                font-size: 1.1rem;
                margin-bottom: 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                        line-clamp: 1; 
                -webkit-box-orient: vertical;
    
                @media only screen and (max-width:480px) {
                    font-size: 80%;
                }
            }
            p {
                margin: 0;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 1; /* number of lines to show */
                        line-clamp: 1; 
                -webkit-box-orient: vertical;
    
                @media only screen and (max-width:480px) {
                    font-size: 60%;
                }
            }
        }
    }
}

.dashboard-list-item-status {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    p {
        margin: 0;
        font-size: 70%;
        text-wrap: nowrap;
        color: rgba(var(--font-color), .75);
    
        @media only screen and (max-width:480px) {
            font-size: 55%;
        }
    }
}

.active-dot {
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: .25rem;
}
.active-dot-red {
    background-color: red;
}
.active-dot-green {
    background-color: green;
}

.dashboard-list-separator {
  width: 100%;
  margin: .5rem auto;
  border-top: 1px solid rgba(var(--font-color), 0.1);
}