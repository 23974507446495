.app__results {
    // background-color: rgb(var(--primary-color));
    // background-image: linear-gradient(to right,
    //   rgb(var(--primary-gradient)),
    //   rgb(var(--secondary-gradient)));
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;

    h1 {
        font-size: 3.2rem;
    }
}

.app__results-baby-img {
    width: 230px;
    height: 230px;
    position: relative;

    img {
        width: 230px;
        height: 230px;
        border-radius: 50%;
        border: 20px solid rgba(var(--bg2-color), 0.25);
    }
}

.stars {
    width: 100%;
    height: 100%;
    position: absolute;
    transform: translate(-50%,-50%);
    top: 50%;
    left: 50%;
  }
  
.star {
    position: absolute;

    &-1 {
        bottom: 104px;
        left: -45px;
        transform: rotate(-40deg) scale(0.6);
        animation: twinkle 6s 0s ease infinite;
    }
    &-2 {
        top: -20px;
        left: 2px;
        transform: rotate(40deg) scale(0.35);
        animation: twinkle 6s .1s ease infinite;
    }
    &-3 {
        top: 90px;
        right: -40px;
        transform: rotate(25deg) scale(0.35);
        animation: twinkle 6s 0.2s ease infinite;
    }
    &-4 {
        bottom: -17px;
        right: 0;
        transform: rotate(10deg) scale(0.45);
        animation: twinkle 6s 0.3s ease infinite;
    }
}

@keyframes twinkle {
    0%, 80%, 100%  {
        filter: opacity(0) brightness(.5);
        transform: rotate(20deg) scale;
    }
    10%, 70% {
        filter: opacity(1) brightness(1);
        transform: rotate(40deg) scale;
    }
}
 
.app__results-baby-info {
    background-color: rgb(var(--bg-color));
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
    padding: 1.5rem;
    margin-left: auto;
    margin-right: auto;
}

.app__results-baby-info-top, .app__results-baby-info-bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.app__results-baby-info-top { margin-bottom: 1.25rem; align-items: center; }
.app__results-baby-info-bottom { margin-top: 1.25rem; }

.baby-info-gender {
    width: 85px;
    height: 85px;
    border-radius: 1rem;
    padding: 1rem;

    &-boy {
        background-color: rgba(var(--gender-boy), .1);
    }
    &-girl {
        background-color: rgba(var(--gender-girl), .1);
        padding: 1.2rem 1rem;
    }
    &-neutral {
        background-color: rgba(var(--font-color), .1);
        color: rgba(var(--font-color), .5);
        padding: 1.25rem 0.5rem;
    }
}

.color-gender-boy {
    color: rgb(var(--gender-boy));
}
.color-gender-girl {
    color: rgb(var(--gender-girl));
}

.baby-info-name {
    flex: 1;
    margin-left: 1rem;

    h5 {
        font-size: 1rem;
    }
    h2 {
        font-size: 1.7rem;
        line-height: 1.5rem;
        margin-bottom: 10px;
    }
    p {
        margin: 0;
    }
}

.app__results-baby-info-separator {
    display: block;
    height: 1px;
    width: 100%;
    border-bottom: 1px solid rgba(var(--font-color), .25);
}

.baby-info-stat {
    width: 100px;
    display: flex;
    flex-direction: column;
    flex: 1;
    color: rgb(var(--font-color));

    svg {
        color: rgba(var(--font-color), .25);
        height: 1.1em;
    }

    h6 {
        margin-top: .6rem;
        margin-bottom: 0;
    }
}

.donut-chart {
    path {
        stroke-width: 3;
        stroke: rgb(var(--bg2-color));
    }
}

.apexcharts-datalabel-label {
    color: rgb(var(--font-color));
    fill: rgb(var(--font-color));
}

.apexcharts-datalabel-value {
    color: rgb(var(--font-color));
    fill: rgb(var(--font-color));
}