.pool-info-circle-lip-white {
    background-color: rgb(var(--bg2-color));
    position: absolute;
    height: 340px;
    width: 100%;
    clip-path: ellipse(80% 50% at 50% -22%);
    // margin-top: -20px;
    border-bottom-left-radius: 100% 20%;
    border-bottom-right-radius: 100% 20%;
    box-shadow: rgba(0, 128, 128, .75) 0px -40px 40px -40px inset;
}

.pool-info-circle-lip-color {
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to right, rgb(var(--primary-gradient)), rgb(var(--secondary-gradient)));
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    height: 340px;
    width: 100%;
    clip-path: ellipse(80% 55% at 50% 38%);
    border-bottom-left-radius: 100% 20%;
    border-bottom-right-radius: 100% 20%;
    box-shadow: rgba(0, 128, 128, 0.75) 0px -40px 40px -40px inset;
}

.top-color {
    width: 100%;
    height: 250px;
    background-color: rgb(var(--primary-color));
    background-image: linear-gradient(to right, rgb(var(--primary-gradient)), rgb(var(--secondary-gradient)));
    position: absolute;
    left: 0;
    top: 0;
    transform: translateY(-50%);
    z-index: -1;
}

// .pool_info {
//     background-color: rgb(var(--primary-color));
//     background-image: linear-gradient(to right,
//       rgb(var(--primary-gradient)),
//       rgb(var(--secondary-gradient)));
// }

.pool_info-card {
    width: 100%;
    color: rgb(var(--bg-color));
    min-height: 200px;
    position: relative;
    // background-color: rgb(var(--bg2-color));
    border-radius: 3rem 3rem 0 0;
    text-align: center;
    padding: 0 2rem 2rem 2rem;

    @media only screen and (max-width:400px) {
        margin-bottom: -2rem;
    }

    h5 {
        font-size: 1.5rem;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2; 
        -webkit-box-orient: vertical;
    }

    p {
        font-size: 100%;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3; 
        -webkit-box-orient: vertical;
    }
}

.pool_info-top {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    color: rgb(var(--font-color));
    margin-top: -7px;
    // margin-bottom: -90px;
    padding: 0 1.5rem;

    p {
        margin-bottom: 0;
        font-size: 70%;
    }

    p, h2 {
        text-align: center;
    }

    h2 {
        font-size: 1.25rem;
        font-weight: 400;
        margin-top: -4px;
    }
}

.pool_info-top-item {
    width: 100px;
    margin-top: 5px;
    flex: 1;
}

.pool_info-img {
    width: 160px;
    height: 160px;
    border-radius: 50%;
    background-color: rgb(var(--bg2-color));
    border: 10px solid rgb(var(--bg2-color));
    text-align: center;
    position: relative;
    z-index: 1;

    @media only screen and (max-width:400px) {
        width: 150px;
        height: 150px;
        margin-bottom: 10px;
    }

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
}

.pool_info-btns {
    position: absolute;
    top: -3.5rem;
    left: 0;
    width: 100%;
    padding: 0 1rem;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.pool_info-btn {
    display: block;
    width: 38px;
    height: 38px;

    a, button {
        display: block;
        width: 100%;
        height: 100%;
        padding: 9px 10px;
        color: rgb(var(--bg-color));
        border-radius: 50%;
        border: solid 1px rgb(var(--bg-color));

        .fa-lg {
            font-size: 1em;
            vertical-align: 0;
        }
    }
    :hover {
        background-color: rgb(var(--bg-color));
        color: rgb(var(--primary-color))
    }
    button:focus {
        outline: none;
    }
}

.pool_info-btn-spacer {
    width: 160px;

    @media only screen and (max-width:400px) {
        width: 150px;
    }
}

.pool_info-edit {
    top: 1.5rem;
    left: 3rem;

    a {
        padding: 9px 10px;
    }
}

.pool_info-share {
    top: 1.5rem;
    right: 3rem;

    button {
        padding: 10px 11px;
    }
}

.pool_info-place-bets {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
}

.pool_info-place-bets, .pool_info-announce {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;

    @media only screen and (max-width: 600px) {
        flex-direction: column;
    }

    a {
        cursor: pointer;
        color: rgb(var(--bg-color));
        border-radius: 2rem;
        padding: 0.7rem 1.75rem;
        min-width: 210px;
        position: relative;
        text-align: center;
        vertical-align: middle;
        -webkit-user-select: none;
        user-select: none;
        background-color: rgba(var(--bg-color), 0.2);
        font-size: 0.9rem;
        line-height: 1.5;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    :hover {
        background-color: rgba(var(--bg-color), 1);
    }
    a:hover {
        color: rgb(var(--primary-color));
        text-decoration: none;
    }
}

.pool_info-announce {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}

.share-pool-popup {
    text-align: center;
}

.share-social-icons {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: space-between;

    a, a:hover {
        color: rgb(var(--bg-color));
        text-decoration: none;
    }

    button, button:focus {
        border: none;
        outline: none;
    }
}

.share-social-icon {
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.75rem;
    margin: 0.5rem 1rem;
    background-color: rgba(var(--font-color), .75);
    color: rgb(var(--bg-color));
}

.share-social-icon.facebook {
    background-color: #4267B2;
}
.share-social-icon.twitter {
    // background-color: #1DA1F2;
    background-color: #000000;
}
.share-social-icon.whatsup {
    background-color: #25D366;
}