.add-results-header {
    color: #fff;
}

#countComment {
    font-weight: 400;
    margin-top: -26px;
    margin-right: 7px;
    float: right;
}

textarea#inputComment {
    resize: none;
}

.gender-button-group {
    display: flex;
    justify-content: space-between;
}

.gender-button-group.is-invalid .gender-button {
    border-color: red;
}

.gender-button {
    width: 47%;
    height: 10rem;
    flex: none !important;
    border-radius: .7rem !important;
    border: 2px solid rgba(var(--font-color), .4);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 1.2em !important;
}

.gender-button-boy {
    color: skyblue;
}

.gender-button-girl {
    color: pink;
}

.gender-button:not(:disabled):not(.disabled):active,
.gender-button:not(:disabled):not(.disabled).active {
    background-color: rgb(var(--bg-color));
    border-color: rgb(var(--primary-color));
}

.gender-button:focus,
.gender-button.focus {
    box-shadow: none !important;
}

.gender-button-boy:not(:disabled):not(.disabled):active,
.gender-button-boy:not(:disabled):not(.disabled).active {
    color: skyblue;
}

.gender-button-girl:not(:disabled):not(.disabled):active,
.gender-button-girl:not(:disabled):not(.disabled).active {
    color: pink;
}

.gender-button:not(:disabled):not(.disabled).active span {
    position: absolute;
    top: -1px;
    right: -1px;
    border-width: 2rem;
    border-style: solid;
    border-color: rgb(var(--primary-color)) rgb(var(--primary-color)) transparent transparent;
    border-image: initial;
    border-radius: 0px .7rem 0px 0px;
    margin: 0px;
    padding: 0px;
}

.toggle-button-checkmark {
    position: absolute;
    color: rgb(var(--bg-color));
    top: .75rem;
    right: .75rem;
}

.weight-display,
.length-display {
    font-size: 2rem;
}

.xl-input input {
    height: 7rem;
    color: rgb(var(--font-color));
}

.xl-input-date input:placeholder-shown {
    padding-bottom: .75rem;
}

.xl-input-date input::placeholder {
    color: rgb(var(--font-color));
}

/* .xl-input span {
    width: 1.75rem;
    right: 1.7rem;
} */

/* .xl-input span svg {
    width: 2.5rem !important;
    height: 2.5rem !important;
    font-size: 2.5rem !important;
} */

.xl-input-date input {
    font-size: 2.2rem;
}

.xl-input-time input {
    font-size: 3rem;
}

#time-picker_cancel-button {
    color: rgb(var(--primary-color));
}

#time-picker_ok-button {
    background-color: rgb(var(--primary-color));
    border-color: rgb(var(--primary-color));
}

#time-picker_modal input,
#time-picker_modal input::placeholder {
    color: rgb(var(--primary-color));
}

#time-picker_modal input:active::placeholder,
#time-picker_modal input:focus::placeholder {
    color: rgb(var(--bg-color));
}

#time-picker_modal input:active,
#time-picker_modal input:focus {
    background-color: rgb(var(--primary-color));
    box-shadow: 0 0 2px rgb(var(--primary-color));
    color: rgb(var(--bg-color));
}

#time-picker_modal span {
    color: rgb(var(--primary-color));
}

#time-picker_modal button svg {
    color: rgb(var(--primary-color));
}