.app__404 {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h3 {
        font-size: 1.5rem;
        @media only screen and (min-width:600px) {
            font-size: 2.5rem;
        }
        @media only screen and (min-width:992px) {
            font-size: 3rem;
        }
    }

    p {
        @media only screen and (min-width:600px) {
            font-size: 1rem;
        }
        @media only screen and (min-width:992px) {
            font-size: 1.25rem;
        }
    }
}

.app__404-glyph {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    margin-top: 5rem;
    margin-bottom: 2rem;

    h2 {
        font-weight: 400;
        font-size: 5rem;
        color: rgb(var(--primary-color));

        @media only screen and (min-width:600px) {
            font-size: 9rem;
        }

        @media only screen and (min-width:992px) {
            font-size: 12rem;
        }
    }
}

.app__404-moon {
    width: 100px;
    height: 100px;
    margin-left: -15px;
    margin-right: -2px;

    @media only screen and (min-width:600px) {
        width: 200px;
        height: 200px;
        margin-left: -30px;
        margin-right: -6px;
    }

    @media only screen and (min-width:992px) {
        width: 300px;
        height: 300px;
        margin-left: -40px;
        margin-right: -10px;
    }
}

