.form-signin {
    width: 100%;
    max-width: 420px;
    padding: 15px;
    margin: auto;
}

.form-label-group {
    position: relative;
    margin-bottom: 1rem;
}

.form-label-group>input,
.form-label-group>label {
    height: 3.125rem;
    padding: .65rem .8rem;
}

.form-label-group>textarea {
    padding: .65rem .8rem;
}

.form-label-group>label {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    margin-bottom: 0;
    /* Override default `<label>` margin */
    line-height: 2.1;
    color: #495057;
    pointer-events: none;
    cursor: text;
    /* Match the input under the label */
    border: 1px solid transparent;
    border-radius: 1rem;
    transition: all .1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder,
.form-label-group textarea::-webkit-textarea-placeholder {
    color: transparent;
}

.form-label-group input:-ms-input-placeholder,
.form-label-group textarea:-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-ms-input-placeholder,
.form-label-group textarea::-ms-input-placeholder {
    color: transparent;
}

.form-label-group input::-moz-placeholder,
.form-label-group textarea::-moz-placeholder {
    color: transparent;
}

.form-label-group input::placeholder,
.form-label-group textarea::placeholder {
    color: transparent;
}

.form-label-group input:not(:placeholder-shown),
.form-label-group textarea:not(:placeholder-shown) {
    padding-top: 1.25rem;
    padding-bottom: .25rem;
}

.form-label-group input:not(:placeholder-shown)~label,
.form-label-group textarea:not(:placeholder-shown)~label {
    padding-top: .25rem;
    padding-bottom: .25rem;
    font-size: 12px;
    color: #777;
}

.form-label-group.signup-label>input,
.form-label-group.signup-label>textarea {
    border-color: transparent;
    border-radius: 1rem;
    background-color: rgba(var(--primary-color), 0.08);
    box-sizing: border-box;
}

/* .label-edit::after {
    content: '✎';
    position: absolute;
    top: .85rem;
    right: 1rem;
    pointer-events: none;
} */

.form-control.is-invalid {
    border-color: #dc3545 !important;
}

/* Fallback for Edge
-------------------------------------------------- */
@supports (-ms-ime-align: auto) {

    .form-label-group>label,
    .form-label-group>textarea {
        display: none;
    }

    .form-label-group input::-ms-input-placeholder,
    .form-label-group textarea::-ms-input-placeholder {
        color: #777;
    }
}

/* Fallback for IE
-------------------------------------------------- */
@media all and (-ms-high-contrast: none),
(-ms-high-contrast: active) {

    .form-label-group>label,
    .form-label-group>textarea {
        display: none;
    }

    .form-label-group input:-ms-input-placeholder,
    .form-label-group textarea:-ms-input-placeholder {
        color: #777;
    }
}